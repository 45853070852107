import { Link } from 'gatsby';
import React, { useState } from 'react';

function Header() {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = (e) => {
        setMenuOpen(!menuOpen)
    }

    return (
        <header className="app-header bg-dark w-100 align-self-start">
            <nav className="navbar navbar-expand-md navbar-dark">
                <div className="container">
                    <Link className="navbar-brand" to="/">
                        <h4 className="mb-0">leadinsight</h4>
                    </Link>

                    <button
                        className={"navbar-toggler" + (menuOpen ? ' not-collapsed' : ' collapsed')}
                        type="button"
                        aria-label="Menü öffnen"
                        onClick={(e) => toggleMenu()}
                    >
                        <span className="icon-bar top-bar"></span>
                        <span className="icon-bar middle-bar"></span>
                        <span className="icon-bar bottom-bar"></span>
                    </button>

                    <div className={"collapse navbar-collapse justify-content-end " + (menuOpen ? ' show' : ' hide')} id="main-nav">
                        <ul className="navbar-nav mb-2 mb-md-0">
                            <li className="nav-item d-flex flex-row">
                                <a href="https://app.leadinsight.de" className="btn btn btn-secondary btn-sm my-auto">Anmelden</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
}

export default Header;
