import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

SectionColumn.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]).isRequired,
	columnWidthSmall: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
	columnWidthMedium:  PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
	columnWidthLarge:  PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
};

function SectionColumn(props) {
	let { columnWidthSmall, columnWidthMedium, columnWidthLarge, classes } = props;

	if(columnWidthSmall) columnWidthSmall = `col-sm-` + columnWidthSmall.toString();
	if(columnWidthMedium) columnWidthMedium = `col-md-` + columnWidthMedium.toString();
	if(columnWidthLarge) columnWidthLarge = `col-lg-` + columnWidthLarge.toString();

	return (
		<div
			className={classnames(`col-12`, columnWidthSmall, columnWidthMedium, columnWidthLarge, classes)}>
			{props.children}
		</div>
	);
}

SectionColumn.defaultProps = {
    classes: ''
}

export default SectionColumn;
