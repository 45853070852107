import React from 'react';
import { Link } from 'gatsby';

import SectionContainer from "../components/common/SectionContainer";
import SectionColumn from "../components/common/SectionColumn";

function Footer() {
    return (
        <footer className="app-footer w-100 text-center p-3 bg-dark text-secondary-light align-self-end">
            <SectionContainer className={`visits-section py-5`}>
                <SectionColumn columnWidthMedium={12}>
                    <div className="text-center text-md-start">
                        <h3 className="text-white">leadinsight by hype.media</h3>
                    </div>
                </SectionColumn>
                <SectionColumn columnWidthMedium={3}>
                    <div className="text-center text-md-start">
                        <address>
                            hype.media Marketing Gruppe <br/>
                            Eiserfelder Str. 316 <br/>
                            57080 Siegen
                        </address>
                    </div>
                </SectionColumn>
                <SectionColumn columnWidthMedium={3}>
                    <div className="text-center text-md-start">
                        <address>
                            hype.media Marketing Gruppe <br/>
                            Weiseler Straße 105 <br/>
                            35510 Butzbach
                        </address>
                    </div>
                </SectionColumn>
                <SectionColumn columnWidthMedium={3} classes="ms-auto">
                    <div className="text-center text-md-start">
                        <nav className="nav flex-column">
                            <Link className="nav-link p-0 text-secondary-light" to="/impressum/">Impressum</Link>
                            <Link className="nav-link p-0 text-secondary-light" to="/datenschutz/">Datenschutz</Link>
                            <a className="nav-link p-0 text-secondary-light" href="https://app.leadinsight.de/opt-out/" target="_blank" rel="noopener noreferrer">Opt-Out</a>
                        </nav>
                    </div>
                </SectionColumn>
            </SectionContainer>

            <div className="footer-inner text-secondary-light">
                <span className="small">©&nbsp;{new Date().getFullYear()} leadinsight by hype.media</span> <br/>
                <p className="smaller mt-3">hype.media Marketing Gruppe ist eine Werbeagentur (Marketing und Online-Agentur) aus Siegen und Butzbach. Unsere Dienstleistungen reichen von der Planung, Konzeption und Strategie bis zur Produktion von Webseiten sowie Suchmaschinenoptimierung (SEO), der Entwicklung von Online Shops, Content Management Systemen (CMS), Programmierung, Werbung, Solcial Media, Konzeption, Kommunikation, Drucksachen (wie Broschüren, Visitenkarten, Textilien), Werbetechnik und Messeständen.</p>
            </div>
        </footer>
    );
}

export default Footer;
