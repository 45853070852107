import React from 'react';
import PropTypes from 'prop-types';

SectionContainer.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]).isRequired,
	className: PropTypes.string
};

function SectionContainer(props) {
	return (
		<section className={props.className && props.className}>
			<div className="section-inner container">
				<div className="section-column-wrap row">
					{props.children}
				</div>
			</div>
		</section>
	);
}

export default SectionContainer;
