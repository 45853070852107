import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import 'assets/stylesheets/app.scss';
import 'assets/stylesheets/fonts.css';

import Header from './Header';
import Footer from './Footer';

const Layout = ({ children, pageName }) => {
    let className = '';

    if ( pageName ) {
        className = `${className} page-${pageName}`;
    }

    return (
        <>
            <Helmet bodyAttributes={{ class: className}}>
                <title>Gatsby Site</title>
            </Helmet>
            <div id="app-shell" className="flex h-screen bg-gray-50 dark:bg-gray-900">
                <Header />
                <main className={`app-content align-self-start mb-auto`}>{ children }</main>
                <Footer />
            </div>
        </>
    );

};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout;
